import { memo } from "react"
import _isEmpty from "lodash/isEmpty"

const StoreDataTags = ({ authData: storeData = {} }) => {
  if (!_isEmpty(storeData)) {
    const formKeys = Object.keys(storeData) ?? []
    return (
      <>
        {formKeys.map(item => (
          <div
            key={item}
            className={`storepage-${item}`}
            value={storeData[item]}
          ></div>
        ))}
      </>
    )
  }
  return null
}

export default memo(StoreDataTags)
